<template>
  <div class="">
    <!--Loading hostaway export spinner-->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner">
        <b-spinner></b-spinner>
      </div>
    </div>
    <!--Filters-->
    <b-card no-body>

      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link class="mr-1" style="color: inherit" @click="resetFilters">
                <feather-icon icon="XIcon" size="16"/>
              </b-link>
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- Id -->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Id") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!--Address-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Address") }}</label>
            <b-form-input
                v-model="filter.like.address"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- House number-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("House number") }}</label>
            <b-form-input
                v-model="filter.like.house"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Zip-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Zip") }}</label>
            <b-form-input
                v-model="filter.like.zip"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!--City-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("City") }}</label>
            <b-form-input
                v-model="filter.like.city"
                class="d-inline-block mr-1"
            />
          </b-col>


          <!-- Rooms-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("HostawayId") }}</label>
            <b-form-input
                v-model="filter.eq.hostawayId"
                class="d-inline-block mr-1"
            />
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>


    <!--Data-->
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Real Estates Exports') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100,500]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->

          </b-row>
        </div>
        <!--Table-->
        <div v-handy-scroll class="table-responsive">
          <b-table
              ref="realEstate-table"
              striped
              hover
              v-bind:table-class="{'action-active':selectedRealEstates.length > 0}"
              v-bind:items="realEstates"
              v-bind:fields="selectedFields"
              :busy="loading"
              @sort-changed="onSort"
              @row-clicked="onRowClick"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mx-1"></b-spinner>
                <strong>{{ msg('Loading') }}...</strong>
              </div>
            </template>


            <template #cell(hostawayId)="data">
              <b-badge variant="success" v-if="data.value!=null && data.value!==''">{{ data.value }}</b-badge>
              <b-badge variant="danger" v-else>No</b-badge>
            </template>
            <template #cell(address)="data">
              <div>
                <div style="white-space: nowrap">
                  <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
                </div>
                <div style="margin-left: 5px">
                  <strong>{{ msg('Zip') }}: </strong>{{ data.item.zip }}
                </div>
              </div>
            </template>
            <template #cell(attachments)="data">
              <b-img
                  v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                  blank-color="#475F7B"
                  rounded
                  alt="Rounded image"
                  class="d-inline-block m-1"
              />
            </template>
            <template #head(id)="data">
              <div class="d-flex justify-content-center">
                <div v-for="(url, platform) in platformLogos" :key="platform" class="text-center"
                     style="margin-right: 5vh">
                  <img :src="url" :alt="data" class="platform-icon">
                </div>
              </div>
            </template>
            <template #cell(id)="data">
              <div v-if="data.item.realEstateTreasure !== null">
                <div class="d-flex align-items-center justify-content-center">
                  <div v-for="(url, platform) in platformLogos" :key="platform" class="text-center"
                       style="margin-right: 10vh">
                    <b-form-checkbox
                        :checked="isChecked(data.item.realEstateTreasure, platform)"
                        switch
                        disabled
                        :class="{
                'custom-control-success': isChecked(data.item.realEstateTreasure, platform),
                'custom-control-danger': !isChecked(data.item.realEstateTreasure, platform)
              }"
                    >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(realEstateTreasure)="data">
              <div v-if="data.value !== null" class="btn-group">
                <b-button
                    v-for="link in filteredLinks(data)"
                    :key="link.key"
                    :class="link.class"
                    :style="link.style"
                    size="sm"
                    v-b-tooltip.hover
                    :title="link.title"
                >
                  <i>{{ link.icon }}</i>
                </b-button>
              </div>
            </template>


            <template #cell(channelExportRequestTime)="data">
              <b-badge v-if="data.value!=null && data.value!==''" variant="danger">
                {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
              </b-badge>

            </template>

          </b-table>
        </div>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

    <export-modal ref="exportModal" @updated="refresh"/>


  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RealEstatePanel from './realEstatePanel'
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import mixinFilter from "@/mixins/mixinFilter";
import smartLockModal from "@/components/modals/nukiOperationsModal.vue";
import ExportModal from "@/components/modals/exportModal.vue";

const realEstateFilter = () => ({
  like: {search: '', name: "", shortDescription: ""},
  eq: {type: "", realEstateArea: {}, featureStatus: null},
  lte: {realEstatePrices: {}},
  gte: {realEstatePrices: {}},
  in: {}
})


export default {
  name: 'RealEstate',
  mixins: [mixinFilter],
  components: {
    ExportModal,
    // eslint-disable-next-line vue/no-unused-components
    PlatformLogo,
    // eslint-disable-next-line vue/no-unused-components
    RealEstatePanel
  },
  data() {
    return {
      loading: true,
      amount: 10,
      ownerId: null,
      buid: null,
      platformLogos: {
        'Airbnb': 'https://treasurehome.at/uploads/airbnb.png',
        'Booking': 'https://treasurehome.at/uploads/booking.png',
        'Expedia': 'https://treasurehome.at/uploads/expedia.png',
        'Agoda': 'https://treasurehome.at/uploads/agoda.png',
        'Vrbo': 'https://treasurehome.at/uploads/Vrbo.svg',
      },
      links: [
        {
          key: 'bookingLink',
          class: 'btn',
          style: 'background-color: rgba(49,121,231,0.73)!important;',
          title: 'Booking',
          icon: 'B'
        },
        {
          key: 'airbnbLink',
          class: 'btn',
          style: 'background-color: rgba(255,82,88,0.79)!important;',
          title: 'Airbnb',
          icon: 'A'
        },
        {
          key: 'expediaLink',
          class: 'btn btn-warning',
          style: '',
          title: 'Expedia',
          icon: 'E'
        },
        {
          key: 'treasureLink',
          class: 'btn',
          style: 'background-color: rgba(199,166,128,0.71)!important;',
          title: 'Treasure Home',
          icon: 'T'
        },
        {
          key: 'agodaLink',
          class: 'btn',
          style: 'background-color: #2ecc71!important;',
          title: 'Agoda',
          icon: 'A'
        },
        {
          key: 'vrboLink',
          class: 'btn',
          style: 'background-color: #bfddea!important;',
          title: 'Vrbo',
          icon: 'V'
        }
      ],
      ids: [
        {
          key: 'bookingId',
          class: 'btn',
          style: 'background-color: rgba(49,121,231,0.73)!important;',
          title: 'Booking',
          icon: 'B'
        },
        {
          key: 'airbnbId',
          class: 'btn',
          style: 'background-color: rgba(255,82,88,0.79)!important;',
          title: 'Airbnb',
          icon: 'A'
        },
        {
          key: 'expediaId',
          class: 'btn btn-warning',
          style: '',
          title: 'Expedia',
          icon: 'E'
        },
        {
          key: 'treasureId',
          class: 'btn',
          style: 'background-color: rgba(199,166,128,0.71)!important;',
          title: 'Treasure Home',
          icon: 'T'
        },
        {
          key: 'agodaId',
          class: 'btn',
          style: 'background-color: #2ecc71!important;',
          title: 'Agoda',
          icon: 'A'
        },
        {
          key: 'vrboId',
          class: 'btn',
          style: 'background-color: #bfddea!important;',
          title: 'Vrbo',
          icon: 'V'
        }
      ],
      isLoading: false,

      realEstates: [],
      selectedRealEstates: [],
      fields: [],
      filter: realEstateFilter(),
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  watch: {
    file: {
      handler() {
        this.uploadFile()
      }
    },
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    fields: {
      deep: true,
      handler() {
        localStorage["real-estates-fields"] = JSON.stringify(this.fields);
      }
    },
    selectedRealEstates: {
      handler() {
        this.$emit("selectedRealEstates", [...this.selectedRealEstates])
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('realEstate', ['allStatus', 'statusVariants', 'getPrices', 'getFeaturedStatus', "getFullAddress"]),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },

    selectedFields() {
      let fields = this.fields.filter(f => f.active);
      return (fields.length > 0) ? fields : this.fields;
    },
    selectedRealEstatesIds: {
      get() {
        return this.selectedRealEstates.map(p => p.id);
      },
      set(selectedRealEstatesIds) {
        let realEstates = this.realEstates.toObject("id");
        this.selectedRealEstates = selectedRealEstatesIds.map(pid => realEstates[pid]);
      }
    },
    isAllRealEstatesSelected() {
      let ids = this.selectedRealEstates.map(p => p.id);
      return this.realEstates.filter(p => !ids.includes(p.id)).length === 0;
    },
    allRealEstateStatus() {
      return this.allStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    allAgents() {

      return this.getAllUsers
          .filter(us => us.roleId === 18)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allRealEstates() {
      return this.realEstates
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.filter = this.loadCachedFilters(realEstateFilter);
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
    this.$root.$on('crm::lang-updated', this.setFields)
    this.$root.$on('crm::export-table::update', this.listeners.refresh)

  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::lang-updated', this.setFields)
    this.$root.$off('crm::export-table::update', this.listeners.refresh)
  },
  methods: {
    ...mapActions('realEstate', ['copyToNextExpensesRealEstate', 'getExcelTemplateUrl']),
    ...mapActions('realEstate', ['getAllRealEstates', 'archiveRealEstate', 'copyLinkSuccess', 'channelExport', 'featureRealEstate', 'deFeatureRealEstate', 'copySuccess']),
    ...mapActions('realEstate', ['getAllDisabledRealEstates', 'activateRealEstate', 'bulkDisable', 'copyRealEstate',
      'fetchRealEstateFromHostaway', 'fetchAllRealEstateFromHostaway', 'bulkAdvertise', 'justimmoUpdate']),
    ...mapActions('realEstate', {_uploadExcel: 'uploadExcel'}),
    filteredLinks(data) {
      return this.links.filter(link => {
        const value = data.value[link.key];
        return value && value.trim() !== '';
      });
    },
    isChecked(realEstateTreasure, platform) {
      const idMappings = {
        'Vrbo': realEstateTreasure.vrboId,
        'Booking': realEstateTreasure.bookingId,
        'Airbnb': realEstateTreasure.airbnbId,
        'Agoda': realEstateTreasure.agodaId,
        'Expedia': realEstateTreasure.expediaId,
      };
      console.log("idMappings[platform]: ", idMappings[platform])
      return idMappings[platform] != null && idMappings[platform] !== '';
    },

    fetchListingFromHostaway(hostawayId) {
      this.fetchListLoading = true;
      this.fetchRealEstateFromHostaway({
        buid: this.hostawayBuid, hostawayId: hostawayId,
        ownerId: this.ownerId, buildingId: this.importBuildingId
      })
          .then((ans) => {
            this.fetchListFromHostaway();

          })
    },

    platformLogoUrl(platformName) {
      return this.platformLogos[platformName];

    },


    channelExportRealEstateById(realId, type) {
      let $this = this;
      this.channelExport({id: realId, featureType: type})
          .then(() => {

            $this.refresh()
            $this.resetChannelInfoModal()
          })
    },


    setFields() {
      let $this = this
      let fields = [
        {
          key: 'channelExportRequestTime',
          label: $this.msg('Active request'),
          sortable: true,
          active: true,
        },
        {
          key: 'attachments',
          label: $this.msg('Main image'),
          sortable: true,
          active: true,
        },

        {
          key: 'name',
          label: $this.msg('Name'),
          sortable: true,
          active: true,
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true,
          active: true,
        },
        {
          key: 'id',
          label: $this.msg('Platform Connected'),
          sortable: false,
          active: true,
        },

        {
          key: 'realEstateTreasure',
          label: $this.msg('Platform Links'),
          sortable: true,
          active: true,
        },

        {
          key: 'hostawayId',
          label: $this.msg('HostawayId'),
          sortable: true,
          active: true,
        },


      ]
      this.fields = fields;
      try {
        let cachedFields = localStorage["real-estates-fields"];
        if (cachedFields != null) {
          let cachedFieldArr = JSON.parse(cachedFields);
          let cachedFieldsMap = cachedFieldArr.toObject("key");
          fields.forEach(field => field.active = cachedFieldsMap[field.key].active != null ? cachedFieldsMap[field.key].active : true);
        }
      } catch (e) {
        localStorage.setItem("real-estates-fields", null);
      }
    },


    openModal(id) {
      this.selectedRealEstateId = id;
      console.log("selectedRealEstateId: ", this.selectedRealEstateId)
      this.$refs.myModal.show();
    },
    // Method to confirm and call the copyRealEstate function
    confirmAndCopy() {
      let $this = this
      this.copyRealEstate({id: this.selectedRealEstateId, duplicate: this.duplicate})
          .then((id) => {
            $this.selectedRealEstateId = null;
            $this.refresh()
            window.open(`/real_estate/deleted_real_estate`, '_blank');

          })
      // After confirmation, close the modal
      this.$refs.myModal.hide();
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.featureInfoModal.type = null;
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    resetChannelInfoModal() {
      this.channelInfoModal.type = null;
      this.$root.$emit('bv::hide::modal', this.channelInfoModal.id)
    },
    getCheckboxModel(realEstateTreasure, platform) {
      const idMappings = {
        'Vrbo': realEstateTreasure.vrboId,
        'Booking': realEstateTreasure.bookingId,
        'Airbnb': realEstateTreasure.airbnbId,
        'Treasure Home': realEstateTreasure.treasureLink,
        'Agoda': realEstateTreasure.agodaId,
        'Expedia': realEstateTreasure.expediaId,
      };
      return idMappings[platform] !== null && idMappings[platform] !== '';
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllRealEstates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'channelExportRequestTime',
        sortby: $this.sort.direction,
      })
          .then(this.onRealEstatesRetrieve, (error) => $this.error = error)

      this.getExcelTemplateUrl().then((url) => $this.excelUrl = url);

    },

    onRealEstatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },
    onRealEstateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.realEstate = true
      }
    },
    copyToNextExpenses() {
      let $this = this;
      this.copyToNextExpensesRealEstate()
          .then(() => {
            $this.refresh()
          })
    },
    countDoneItems(todo) {
      if (todo == null) return "0/0 jobs";
      const doneItems = todo.filter((item) => item.done === true);
      const totalItems = todo.length;
      return `${doneItems.length}/${totalItems} jobs`;
    },

    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(item) {
      this.$refs.exportModal.showById(item.id);
    },
    submitRealEstate() {
      this.$root.$emit('crm::submitRealEstate')
    },
    onSubmitted(server) {
      console.log("RealEstate -> onSubmitted()", server)
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.realEstate = false;
    }
  }
}
</script>

<style lang="scss">
.features-group .btn-group-toggle {
  width: 100%;

  i {
    font-size: 26px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #f69c55;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed */
}

.loading-spinner {
  text-align: center;
}

.platform-icon {
  width: 40px;
  height: 40px;
}

.custom-control-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #28a745;
}

.custom-control-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #dc3545;
}

/*.features-group .btn {
  width: 100%;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  //color:  !important;
}*/
</style>
