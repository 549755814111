<template>
  <div class="">
    <b-modal ref="exportModal" size="xl" title="Loading" centered @ok="save" content-class="p-0"
             no-close-on-backdrop ok-variant="success" ok-title="Save" cancel-title="Close" cancel-variant="danger"
    >
      <template v-if="realEstate!=null" #modal-title>
        {{ realEstate.fullAddress }} - <span style="color: #a10707">{{ getAllRequests }}</span>
      </template>
      <div style="min-height: 150px">
        <div v-if="realEstate != null">
          <div class="d-flex">
            <feather-icon icon="LogOutIcon" size="25"/>
            <h4 class="mb-0 ml-50">
              {{ msg('External export') }}
            </h4>
          </div>
          <validation-observer ref="refFormObserver">

          <b-row class="mt-1 w-100">
            <b-col cols="6">
              <b-form-group label="Hostaway ID" label-for="realEstate-buy-price">
                <b-form-input type="number" v-model="realEstate.hostawayId"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Father Hostaway ID" label-for="realEstate-buy-price">
                <b-form-input type="number" v-model="realEstate.fatherHostawayId"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <platform-logo v-bind:platform="'Booking'">
                {{ msg('ID') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <validation-provider #default="{ errors }" :name="msg('Id')" rules="min:8">

                  <b-form-input type="number" size="sm" v-model="realEstate.realEstateTreasure.bookingId"/>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col cols="9">
              <platform-logo v-bind:platform="'Booking'">
                {{ msg('Link') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <b-form-input  size="sm" v-model="realEstate.realEstateTreasure.bookingLink"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <platform-logo v-bind:platform="'Airbnb'">
                {{ msg('ID') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <validation-provider #default="{ errors }" :name="msg('Id')" rules="min:8">

                <b-form-input  type="number"  size="sm" v-model="realEstate.realEstateTreasure.airbnbId"
                />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="9">
              <platform-logo v-bind:platform="'Airbnb'">
                {{ msg('Link') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <b-form-input  size="sm"  v-model="realEstate.realEstateTreasure.airbnbLink"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <platform-logo v-bind:platform="'Expedia'">
                {{ msg('ID') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <validation-provider #default="{ errors }" :name="msg('Id')" rules="min:8">

                  <b-form-input type="number" size="sm" v-model="realEstate.realEstateTreasure.expediaId"
                />  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="9">
              <platform-logo v-bind:platform="'Expedia'">
                {{ msg('Link') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <b-form-input  size="sm" v-model="realEstate.realEstateTreasure.expediaLink"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <platform-logo v-bind:platform="'Agoda'">
                {{ msg('ID') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <validation-provider #default="{ errors }" :name="msg('Id')" rules="min:8">

                <b-form-input type="number" size="sm"  v-model="realEstate.realEstateTreasure.agodaId"
                />  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="9">
              <platform-logo v-bind:platform="'Agoda'">
                {{ msg('Link') }}
              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <b-form-input size="sm"  v-model="realEstate.realEstateTreasure.agodaLink"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <platform-logo v-bind:platform="'Vrbo'">
                {{ msg('ID') }}

              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <validation-provider #default="{ errors }" :name="msg('Id')" rules="min:8">

                  <b-form-input type="number" size="sm" v-model="realEstate.realEstateTreasure.vrboId"
                />  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col cols="9">
              <platform-logo v-bind:platform="'Vrbo'">
                {{ msg('Link') }}

              </platform-logo>
              <b-form-group style="margin-top: 5px">
                <b-form-input size="sm" v-model="realEstate.realEstateTreasure.vrboLink"
                />
              </b-form-group>
            </b-col>


          </b-row>
          </validation-observer>

          <b-row>

            <b-col cols="3">
              <b-form-group :label="msg('Hostaway reservations')" label-for="realEstate-sell-price">
                <b-button variant="outline-danger" @click="fetchHostawayReservations">
                  <b-spinner small v-if="hostawayLoading"></b-spinner>
                  Fetch Hostaway reservations
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :label="msg('Total reservations')" label-for="realEstate-sell-price">
                <div v-if="totalReservations!=null" class="text-center"
                     style="font-weight: bold;background-color: #496ca1;padding: 5px;border-radius: 10px;color: whitesmoke">
                  {{ totalReservations }}
                </div>
                <b-spinner small v-else></b-spinner>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div v-else class="position-absolute w-100 text-center text-primary" style="top:50%">
          <div>
            <b-spinner label="Spinning" variant="primary"/>
            {{ msg('loading') }}
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import {msg} from "@babel/core/lib/config/validation/option-assertions";

export default {
  name: 'exportModal',
  components: {PlatformLogo},
  data: () => ({
    realEstate: null,
    loading: true,
    error: null,
    export: {},
    hostawayLoading: false,
    totalReservations: null,

  }),
  props: {},

  created() {

  },
  computed: {
    getAllRequests() {
      let result = '';
      if (!this.realEstate.channelExport) {
        return result;
      }
      const entries = Object.entries(this.realEstate.channelExport);
      entries.forEach(([key, value], index) => {
        result += `${key} (${this.formatDateTime((value))})`;
        if (index < entries.length - 1) {
          result += ' - ';
        }
      });
      return result.trim();
    },
  },
  methods: {
    msg,
    ...mapActions('realEstate', ['getRealEstateById', 'getRealEstateReservationCountById']),
    ...mapActions('realEstate', ['fetchHostawayContracts']),
    ...mapActions('realEstate', {
      _getRealEstate: 'getRealEstateById',
      _updateExportRealEstate: 'updateExportRealEstate',
      _updateDocumentFile: 'updateDocumentFile',
      _loadRealEstateData: 'getData'
    }),
    formatDateTime(dateTime) {
      return this.$moment(dateTime, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm")
    },
    fetchHostawayReservations() {
      this.hostawayLoading = true; // Start loading
      this.fetchHostawayContracts(this.realEstate.id)
          .then(() => {
            // Handle success if needed
          })
          .catch(error => {
            // Handle error if needed
            console.error("An error occurred:", error);
          })
          .finally(() => {
            this.hostawayLoading = false; // Stop loading on either success or error
          });
    },
    save() {
      let $this = this
      this._updateExportRealEstate(this.realEstate)
          .then(
              (ans) => {
                $this.$root.$emit('crm::export-table::update');
              }
          )
    },

    showById(id) {
      let $this = this;
      this.realEstate = null;
      this.totalReservations = null;
      $this.show();

      this.getRealEstateById(id).then(response => {
        $this.realEstate = response;
        if (response != null && response.buildingId != null)
          $this.getRealEstateReservationCountById(id)
              .then(response => {
                $this.totalReservations = response.totalReservations;

              });
        else $this.totalReservations = "No building connected to this real estate";
      });

    },
    show(realEstate) {
      this.$refs.exportModal.show();
    },
  }
}
</script>

<style scoped>

</style>
